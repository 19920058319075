<template>
  <div class="about-setting block-style" style="padding: 15px;">
    <div class="flex-between" v-if="element.message">
      <div class="date-naw">Дата создания: {{ editDate(element.created_at) }}</div>

      <!--
        <div>
          <span v-html="statusIcon[element.status]"></span>
          <span class="status-message">  {{element.status}}</span>
        </div>
      -->
    </div>
    <div style="display: flex; flex-wrap: wrap;">
      <div style="flex: 1; margin: 5px; text-align: left; margin-right: 20px;">
        <div class="header-mailing">
          {{ element.name }}
        </div>
        <div>
           
            <img class="img-mailing" v-if="element.message.media && (element.message.media_type=='image' || element.message.media_type=='text')" 
                loading="lazy"
                :src="getImage(element.message.media)" alt="" 
            >
            <video id="videoElement" v-if="element.message.media && element.message.media_type=='video'" 
                loading="lazy"
                style=" max-width: 280px;" controls>
                <source :src="localVideo">
            </video>
            <div class="header-description" 
              style="white-space: normal; word-wrap: break-word;"
              v-if="element.message" 
              v-html="element.message.text" 
            />
            <div>
          </div>
        
          <div v-if="element.buttons.length > 0">
           
            <AppButton v-for="(item, index) in element.buttons" :key="index"
              style=" height: 20px; margin: 2px; font-size: 10px; margin-top: 10px;"
                  :styleButton="'_blue-external'"> 
                  {{item.name}}
            </AppButton>
          </div>
          <div class="line"></div>
        </div>
      </div>
      <div style="flex: 1; margin: 5px; text-align: left;">
        <div class="header-mailing">
          Информация 
        </div>
        <div>
    
          <div class="views" v-if="element.job!='deleteMessageTelegram'">
            Начало рассылки: {{ (element.send_date) ? editDate(element.send_date)+" ("+timeZone+")" : "" }} 
           
          </div>
         
          <div class="views" v-if="showDifferenc(element.send_date) && (element.status!='draft' && element.job!='cancelMessage') && element.status!='processing' && element.job!='deleteMessageTelegram'">
            Рассылка через: {{ getTimeDifference (element.send_date) }}
          </div>
          <div class="views" v-if="element.info_filter && element.job!='deleteMessageTelegram'">
            Фильтр: {{ (element.info_filter)?element.info_filter.title :"" }} 
          </div>
       
          <div class="views" v-if="jobs.find(e=>e.name == element.job) && element.status!='canceled' && element.status!='draft'">
            Текущая задача: {{ (jobs.find(e=>e.name == element.job))?jobs.find(e=>e.name == element.job).title:"" }}
          </div>
         
          <div class="views" v-if="statuses.find(e=>e.name == element.status)">
            Статус: {{ (statuses.find(e=>e.name == element.status))?statuses.find(e=>e.name == element.status).title:"" }} 
          </div>
          <div class="views" v-if="element.job!='deleteMessageTelegram'">
            Закрепить: {{ element.params && element.params.includes('pin') ? 'Да' : 'Нет' }}
          </div>
          <div class="views" v-if="element.job!='deleteMessageTelegram'">
            Без звука: {{ element.params && element.params.includes('mute') ? 'Да' : 'Нет' }}
          </div>
          <div class="views" v-if="element.job!='deleteMessageTelegram'">
            👍	👎: {{ element.params && element.params.includes('likes') ? 'Да' : 'Нет' }}
          </div>
          <div class="views" v-if="element.job!='deleteMessageTelegram'">
            Защить от копирования и пересылки: {{ element.params && element.params.includes('protect_content') ? 'Да' : 'Нет' }}
          </div>
          <div class="views" v-if="element.job!='deleteMessageTelegram'">
            Скрыть кнопки после нажатия: {{ element.params && element.params.includes('hidden_button') ? 'Да' : 'Нет' }}
          </div>
        
          <div class="views" >
          </div>
          <div class="header-mailing" v-if="element.job!='deleteMessageTelegram'">
            Статистика 
          </div>
        </div>
        <div>
          
          <div class="views" v-if="element.job!='deleteMessageTelegram'">
            Количество получателей:  {{ element.total_recipients }}
            <span></span>
          </div>
          <div class="views" v-if="element.job!='deleteMessageTelegram'">
            Доставлено:  {{element.success_recipients }} 
          </div>
          <div class="views" v-if="element.job!='deleteMessageTelegram'">
            Не доставлено:  {{ element.error_recipients }}
          </div>
          
          <!--
          <div class="views" >
            Переходы по ссылке: {{ element.error_count }}
          </div>
          -->
         
          <div class="views" v-if="isLoading && this.element.message.likes && element.job!='deleteMessageTelegram'">
            <!-- Отображение спиннера или текста о загрузке -->
            Загрузка...
          </div>
          <!-- Отображение данных о лайках после загрузки 
          <div class="views" v-if="!isLoading && this.element.message.likes && element.job!='deleteMessageTelegram'">
            Реакции: {{ likes[0] }}👍 {{ likes[1] }}👎
          </div>
          -->
        
          <div class="views" v-if="element.buttons.length>0 && element.job!='deleteMessageTelegram'">
            <!-- Отображение данных о лайках после загрузки -->
         
            <span v-for="(item, index) in element.buttons" :key="index">
              {{ item.name }}  {{ item.clicks }} кликов/переходов<br>
            </span>
          </div>

          <div class="views" v-if="element.execution_time && element.status!='draft' && element.job!='deleteMessageTelegram'">
            Время рассылки:  {{ getExecution(element.execution_time) }}
            <span></span>
          </div>
        </div>
      </div>
    </div>
   
    <div class="button-block" v-if="element.status=='error'">
        <div class="button-block2">
        <span style="color: red;">Ошибка рассылки! Обратитесь к администратору указав код {{ element.id }} </span>
        </div>
    </div>
    <div class="button-block" v-if="element.status!='completed'  && element.job=='unPinTelegramMessage'">
        <div class="button-block2">
        Выполняется открепление сообщений, дождитесь завершения...
        </div>
    </div>
    <div class="button-block" v-if="element.status!='completed'  && element.job=='deleteMessageTelegram'">
        <div class="button-block2">
        Выполняется удаление сообщений, дождитесь завершения...
        </div>
    </div>


    
    <div class="header-mailing" >
      <AppButton 
            @click="showBots=!showBots"
            style="width: 30%; height: 30px; margin: 2px; font-size: 15px; margin-top: 10px;"
                :styleButton="'_blue-external'"> 
                Боты ({{element.shops.length}}) 
      </AppButton>
      
      <AppButton v-if="(stat_to_google==false || stat_to_google==true) && shop_elements.includes('PageCheckPhone')"
          @click="maillingToGoogleTable(element)"
          style="width: 30%; height: 30px; margin: 2px; font-size: 15px; margin-top: 10px;  cursor: pointer;"
              :styleButton="'_blue-external'"> 
              Данные в google таблицу {{ (!stat_to_google)?"❌":"✅" }} 
      </AppButton>
    </div>
  
    <div style="display: flex; flex-wrap: wrap;" v-if="element.shops.length>0 && showBots"> 
      <AppButton v-for="(item, index) in element.shops" :key="index"  
            style="width: 20%; height: 25px; margin: 2px; font-size: 11px;"
                :styleButton="'_blue-external'"> 
                {{ item.name }}
      </AppButton>
    </div>
    <div class="button-block" v-if="!loadButton">
        <div class="button-block2">
         
          <AppButton  v-if="element.status=='draft' || element.job=='cancelMessageTelegram'"
              @click="goEdit(element)"
              style="width: 100px; height: 30px; margin: 0 5px; cursor: pointer;"
              :styleButton="'_blue-external'"
          >
            Редактировать 
          </AppButton>
          <AppButton v-if="element.status=='draft' || element.job=='cancelMessageTelegram'"
              @click="sendTest(element)"
              style="width: 100px; height: 30px; margin: 0 5px; cursor: pointer;"
              :styleButton="'_blue-external'"
          >
            Тест 
          </AppButton>
          <AppButton  v-if="element.status=='draft' || element.job=='cancelMessageTelegram'"
              @click="sendAction(element.id, 'sendMessage')"
              style="width: 100px; height: 30px; margin: 0 5px; cursor: pointer;"
              :styleButton="'_blue-external'"> 
              запланировать отправить
        </AppButton>

        <AppButton  v-if="element.status=='completed'  
              && element.job=='sendMessageTelegram' 
              && (element.params && element.params.includes('pin')) 
              && element.params && !element.pin
              && element.job!='unPinTelegramMessage'"
              @click="sendAction(element.id, 'unpined')"
              style="width: 100px; height: 30px; margin: 0 5px; cursor: pointer;"
              :styleButton="'_blue-external'"> 
              открепить
        </AppButton>

        <AppButton v-if="element.status=='processing' && element.job=='sendMessageTelegram'" 
              @click="sendAction(element.id, 'stoped')"
              style="width: 100px; height: 30px; margin: 0 5px; cursor: pointer;"
              :styleButton="'_blue-external'"> 
              остановить
        </AppButton>

        <AppButton v-if="element.status=='pending'  && element.job=='sendMessageTelegram'" 
              @click="sendAction(element.id, 'canceled')"
              style="width: 100px; height: 30px; margin: 0 5px; cursor: pointer;"
              :styleButton="'_blue-external'"> 
              отменить
        </AppButton>

        <AppButton v-if="element.delete_time>0 && element.status=='completed' && (element.job=='sendMessageTelegram' || element.job=='unPinTelegramMessage')" 
              @click="sendAction(element.id, 'deleted')"
              style="width: 250px; height: 30px; margin: 0 5px; cursor: pointer;"
              :styleButton="'_blue-external'"> 
              удалить сообщения  {{element.delete_time}} ч.
        </AppButton>
        <!--
        <AppButton v-if="element.status=='completed'  && element.job=='sendMessage'" 
              @click="sendAction(element.id, 'edited')"
              style="width: 250px; height: 30px; margin: 0 5px; cursor: pointer;"
              :styleButton="'_blue-external'"> 
              редактировать сообщение {{element.delete_time}} ч.
        </AppButton>
        -->

      

        <AppButtonRemove v-if="element.status=='draft'" 
                @click="goDelete(element)"
                style="width: 30px; height: 30px;  margin: 0 5px;"
          />
        </div>
    </div>
  
  </div>
</template>

<script>
import api from "./api"
export default {
name: "MaillingShortElement",
props: {
  shop_elements: {
    type: Array,
  
  },
  element: {
    type: Object,
    required: true
  },
  statuses: {
    type: Array,
    required: true
  },
  jobs: {
    type: Array,
    required: true
  }
},
data() {
  return {
    buttons: [],
    stat_to_google: null,
    showBots:false,
    loadButton:false,
    timeZone: localStorage.getItem('timeZone'),
    localVideo: null,
    likes: [null,null],
    isLoading: true,
    statusIcon: {
      draft: '' +
          '<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<path fill-rule="evenodd" clip-rule="evenodd" d="M8.17109 0.0279265C8.20538 0.044205 8.6259 0.450914 9.10559 0.931746C9.87861 1.70663 9.9793 1.81452 9.99154 1.88114C10.0218 2.04607 9.99996 2.07582 9.42222 2.65606L8.88713 3.19347L7.84638 2.15291L6.80564 1.11235L7.32337 0.594138C7.63294 0.284279 7.87252 0.0603468 7.91926 0.0371504C8.01282 -0.00928155 8.08699 -0.0119979 8.17109 0.0279265ZM7.43078 2.57791L8.46628 3.61366L6.03363 6.04662C4.69567 7.38475 3.59221 8.47959 3.58148 8.47959C3.55594 8.47959 1.51991 6.44354 1.51991 6.418C1.51991 6.39873 6.36618 1.54216 6.38541 1.54216C6.39084 1.54216 6.86125 2.00824 7.43078 2.57791ZM3.06801 8.83359C3.03438 8.86453 0.386631 9.98677 0.322833 9.99713C0.152014 10.0249 -0.0249988 9.84683 0.0029239 9.67537C0.0093721 9.63572 0.261731 9.02726 0.563722 8.32325C0.865712 7.61925 1.12557 7.01282 1.14121 6.97563L1.16962 6.90804L2.12591 7.86427C2.65188 8.3902 3.07582 8.8264 3.06801 8.83359Z" fill="#8EA2B0"/>\n' +
          '</svg>\n',
      send: '' +
          '<svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.33777 0.548282C7.64045 0.849835 7.88814 1.12254 7.88814 1.15426C7.88814 1.18593 6.81062 2.28919 5.49358 3.60595L3.09902 6L1.54951 4.45122C0.697311 3.59941 0 2.87742 0 2.84677C0 2.77324 1.08006 1.69032 1.15342 1.69032C1.18463 1.69032 1.63527 2.11487 2.15481 2.63368L3.09942 3.5771L4.888 1.78852C5.87165 0.804872 6.70148 0 6.73196 0C6.76244 0 7.03509 0.24673 7.33777 0.548282Z" fill="#8EA2B0"/>\n' +
          '</svg>\n',
      Delivered: '' +
          '<svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.33777 0.548282C7.64045 0.849835 7.88814 1.12254 7.88814 1.15426C7.88814 1.18593 6.81062 2.28919 5.49358 3.60595L3.09902 6L1.54951 4.45122C0.697311 3.59941 0 2.87742 0 2.84677C0 2.77324 1.08006 1.69032 1.15342 1.69032C1.18463 1.69032 1.63527 2.11487 2.15481 2.63368L3.09942 3.5771L4.888 1.78852C5.87165 0.804872 6.70148 0 6.73196 0C6.76244 0 7.03509 0.24673 7.33777 0.548282Z" fill="#2CBA15"/>\n' +
          '</svg>\n',
      Read: '' +
          '<svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.33777 0.548282C7.64045 0.849835 7.88814 1.12254 7.88814 1.15426C7.88814 1.18593 6.81062 2.28919 5.49358 3.60595L3.09902 6L1.54951 4.45122C0.697311 3.59941 0 2.87742 0 2.84677C0 2.77324 1.08006 1.69032 1.15342 1.69032C1.18463 1.69032 1.63527 2.11487 2.15481 2.63368L3.09942 3.5771L4.888 1.78852C5.87165 0.804872 6.70148 0 6.73196 0C6.76244 0 7.03509 0.24673 7.33777 0.548282Z" fill="#2CBA15"/>\n' +
          '<path fill-rule="evenodd" clip-rule="evenodd" d="M10.3964 0.548282C10.6991 0.849835 10.9468 1.12254 10.9468 1.15426C10.9468 1.18593 9.86929 2.28919 8.55226 3.60595L6.1577 6L5 4.73356C5 4.73356 5.0029 4.76421 5.0029 4.73356C5.0029 4.66003 6.08295 3.5771 6.15631 3.5771C8.10231 5.46389 5.63855 3.05829 6.15809 3.5771L7.94667 1.78852C8.93032 0.804872 9.76016 0 9.79064 0C9.82112 0 10.0938 0.24673 10.3964 0.548282Z" fill="#2CBA15"/>\n' +
          '</svg>\n',
    },
    statusMessage: {
      draft: 'Черновик',
      send: 'Запланирована на ',
      now: 'Отправляется',
      sent: 'Отправлено',
      Delivered: 'Доставлено',
      Read: 'Прочитано',
    },
    showMessageForm: false,
    answer: "",
    filterContent: {
      title: 'Фильтр по товарам',
      filters: {
        sort: {
          title: 'Сортировать:',
          type: 'radio',
          current: "",
          items: [
            {id: 'created_at,asc', name: 'По дате рассылки ↓'},
            {id: 'created_at,asc', name: 'По дате рассылки ↑'},
          ],
        },
        status: {
          title: 'Выбрать статус',
          type: 'radio',
          elem: 'filter',
          name: 'status',
          items: [
            {id: 'all', name: 'Выбрать все'},
            {id: 'clients', name: 'Клиенты'},
            {id: 'visitor', name: 'Посетители'},
          ],
          current: "",
        }
      }
    },
    filterState: {
      title: 'Фильтр по товарам',
      filters: {
        status: {
          type: 'radio',
          elem: 'filter',
          name: 'status',
          items: [
            {id: 'all', name: 'Все'},
            {id: 'clients', name: 'Черновик'},
            {id: 'visitor', name: 'Отправлено'},
          ],
          current: "",
        }
      }
    },
    currentFilterState: '',
  }
}, 
mounted(){
  if (this.element.message.media  && this.element.message.media_type=='video') this.setVideo(this.element.message.media)
  this.stat_to_google = this.element.stat_to_google
  //this.loadLikes();
},
methods: {
  async maillingToGoogleTable(element){
    this.stat_to_google = !this.stat_to_google
    let data = {
      "id":element.id,
      "status":this.stat_to_google
    }
    const response = await api.maillingToGoogleTable(data);
    console.log(response)
    //
  },
  getStatus(){
   
    return this.statuses.find(e=>e.name == this.element.status)
  },
  async loadLikes() {
      try {
        const response = await api.getMaillingInform(this.element.id); // Замените на свой URL
       
 

        // Обновляем переменные с данными о лайках
        this.likes = response.data.likes;
        this.buttons = response.data.buttons;
        this.stat_to_google = response.data.stat_to_google;

        // Устанавливаем isLoading в false, чтобы скрыть спиннер
        this.isLoading = false;
      
      } catch (error) {
        console.error('Ошибка при загрузке данных', error);
        // Обработка ошибки при загрузке данных
      }
    },
  showDifferenc(sendDate){
    if (!sendDate)   return false
  
    // Преобразовываем строку GMT в объект Date
    const gmtDate = new Date(sendDate+ ' GMT');
    
    // Получаем текущее локальное время
    const currentDate = new Date();
    
    // Сравниваем времена
    return gmtDate >= currentDate;
    
  },
  getExecution(seconds){
    if (isNaN(seconds) || seconds < 0) {
      return "";
    }

    const days = Math.floor(seconds / 86400); // 1 день = 86400 секунд
    const remainingSeconds = seconds % 86400;
    const hours = Math.floor(remainingSeconds / 3600); // 1 час = 3600 секунд
    const remainingMinutes = (remainingSeconds % 3600) / 60;
    const finalSeconds = remainingSeconds % 60;

    let result = "";
    if (days > 0) {
      result += days + " д. ";
    }
    if (hours > 0) {
      result += hours + " ч. ";
    }
    if (remainingMinutes > 0) {
      result += Math.floor(remainingMinutes) + " м. ";
    }
    if (finalSeconds > 0) {
      result += Math.floor(finalSeconds) + " с.";
    }

    return result.trim(); // Убираем лишние пробелы с начала и конца строки
  },
  getTimeDifference(sendDate){
      let startDate = new Date()
      console.log(startDate)
      return this.formatTimeDifference(new Date(), new Date(sendDate+ ' GMT'))
  },
  setVideo(videoFile){
    this.localVideo = `https://lk.ivitrina.store/medias${videoFile.link}${videoFile.name}`
    var video = document.getElementById('videoElement');
    if (!video) return
    var source = document.createElement('source');
    source.setAttribute('src', this.localVideo);
    source.setAttribute('type', 'video/mp4');
    video.load();
  },
  editDate(val){
      if (val){
        let unixTime = new Date(val).getTime()- (new Date().getTimezoneOffset() * 60 * 1000)
        return  new Date(unixTime).toLocaleString('ru-RU', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              });
      }  
      return  new Date(val).toLocaleString().slice(0,-3)
  },
  sendAction(id, action){
    this.loadButton = true
    api.action(id,{action:action}).then((response) => {
        this.showMessageNotice('Ошибка 09',response.data.data.message,'success');
        console.log(response.data.data)
       
          let x = {status:response.data.data.status, job:response.data.data.job}
          console.log(x)
          this.$emit('goUpdate', this.element.id, x)
     
        this.loadButton = false;
        }).catch((error) => {
          this.showMessageNotice('Ошибка 09',error.response.data.data.message,'error');
        })
  },
  sendTest(element){
      
     element.recipient = localStorage.getItem("recipient")
     element.availableShop_id= localStorage.getItem("shop_id")
      if (this.element.type_action=='task') {
        if (!this.element.task_id) {
            this.showMessageNotice('Ошибка 09',"Выберите задание",'error');
            return
        }
        let task =  this.tasks.find(e=>e.id==this.model.task_id)
       element.name = task.name
       element.message.text = task.name

      }
      //Проверка для отправки теста
      if (!this.element.availableShop_id) {
        this.showMessageNotice('Ошибка 09',"Выберите бота в который будт отправлена тестовая рассылка",'error');
        return
      }
      if (this.element.message.text.length>970) {
        this.showMessageNotice('Ошибка 09',"Текст рассылки слишком длинный. <br> Пожалуйста сократите его до 970 символов",'error');
        return
      }
      if (!this.element.recipient) {
        this.showMessageNotice('Ошибка 09',"Отсутствует получатель тестовой рассылки. <br> Вам необходимо авторизоватся через телеграм",'error');
        return
      }
      

      api.testMailing(this.element).then((response) => {
        this.showMessageNotice('Ошибка 09',response.data.message,'success');
          }).catch((error) => {
            this.showMessageNotice('Ошибка 09',error.response.data.message,'error');
          })
    },
  goSend(element){
    this.$emit('data', {action: 'send_to', value: element})
  },
  goDelete(element){
    this.$emit('goDelete', element.id)
  },
  getImage(image){
    return 'https://lk.ivitrina.store/medias'+image.link+'thumb/'+image.name+".webp?"
  },
  goEdit(element){
    this.$router.push({ name: "MaillingElement", params: { id: element.id, type: element.type}})
  },
  goInfo() {
 
    this.$emit('data', {action: 'nextPage', value: {name:'ChatReadySend'}});
  },
  getClass(status) {
    let classS = "_blue-external"
    if (status != 1) classS = classS + ' _no-active'
    return classS
  },
  updateAnswer(text, index) {
    this.chats.find(e => e.id == index).editAnswer = text
  },
  sendMessage(chat, status_id) {
    this.$emit('sendMessage', chat, status_id)
  },
  filtered(data) {
    this.$emit('data', {action: 'filterContent', value: data})
  },
  next() {
    this.$emit('data', {action: 'nextPage', value: 'ChatCreate'});
  },
  formatTimeDifference(startDate, endDate) {
      const timeDifference = endDate - startDate;
      const millisecondsPerMinute = 60 * 1000;
      const millisecondsPerHour = 60 * millisecondsPerMinute;
      const millisecondsPerDay = 24 * millisecondsPerHour;

      const days = Math.floor(timeDifference / millisecondsPerDay);
      const hours = Math.floor((timeDifference % millisecondsPerDay) / millisecondsPerHour);
      const minutes = Math.floor((timeDifference % millisecondsPerHour) / millisecondsPerMinute);

      let result = '';
      if (days > 0) {
        result += `${days} д.${days > 1 ? '' : ''} `;
      }
      if (hours > 0) {
        result += `${hours} ч.${hours > 1 ? '' : ''} `;
      }
      if (minutes > 0) {
        result += `${minutes} м.${minutes > 1 ? '' : ''}`;
      }

      return result.trim();
    }
},
inject: ['showMessageNotice'],
}
   

    
</script>

<style lang="scss" scoped>
.chats-table__content {

padding: 11px 13px;
margin-bottom: 14px;
}

.chats {
display: flex;
flex-direction: column;
grid-gap: 10px;
}

.chat {
position: relative;
padding-top: 8px;

&::before {
  background: var(--global_block_line);
  content: "";

  position: absolute;
  top: 0;
  height: 2px;
  width: 100%;
}

&:first-child {
  padding-top: 0;

  &::before {
    background: none;
    width: 0;
    height: 0;
  }
}
}

.chat__info {
display: flex;
flex-direction: column;
row-gap: 5px;
font-size: 12px;

.chat__price {
  font-size: 12px;
  text-align: right;
}
}

.chat__id {
color: var(--default_background);
font-size: 14px;
font-weight: 500;
}

.chat__message {
color: var(--global_third_color);
font-size: 10px;
}

.chat__user {
color: var(--global_second_color);
font-size: 14px;
}

.chat__additional-info_span {
font-size: 12px;
}

.chat__interaction_text-span {
font-size: 12px;
}

.chat__interaction {
border-top: 1px solid var(--global_block_line);
margin-top: 9px;
padding-top: 9px;
}

.chat__interaction__form {
display: flex;
flex-direction: column;
row-gap: 11px;

.chat__interaction_button {
  width: 82px;
}
}

.chat__interaction_hint {
font-size: 12px;
}

.chat__interaction__form_textarea {
height: 76px;
margin-bottom: 5px;
}

.chat__interaction_buttons {
display: flex;
align-items: center;
justify-content: space-between;
}

.products__button {
margin: 10px 0;
}

.chat__interaction_button {
column-gap: 5px;
width: 131px;
height: 30px;
}

.order-filter__type_ul {
display: flex;
justify-content: space-between;
}

.order-filter__type_ul {
display: flex;
flex-wrap: wrap;
}

.order-filter {
display: flex;
justify-content: space-between;
}

.product-edit__status_li {
width: 100%;
margin: 10px 0;
}

.products__button_svg {
margin-left: 10px;
}

.date-naw {
font-style: normal;
font-weight: 400;
font-size: 11px;
line-height: 13px;
color: rgba(0, 0, 0, 0.5);
margin-bottom: 8px;
}

.status-message {
font-family: Roboto;
color: #8EA2B0;
font-size: 9px;
}

.flex-between {
display: flex;
justify-content: space-between;
}

.header-mailing {
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;
color: #000000;
margin: 12px 0;
}
.views {
//color: #8EA2B0;
font-size: 12px;
margin-bottom: 2px;
}

.button-block {
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 20px;
}

.button-block2 {
display: flex;
justify-content: space-between;
align-items: center;
}

.header-description {
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 12px;
color: black;
margin: 12px 0;
}

.img-mailing {
max-width: 280px;
height: auto;
}
.line {
  width: 100%; /* Задаем полосу на всю ширину контейнера */
  height: 1px; /* Высота полосы (можете настроить по вашему выбору) */
  background-color: #000; /* Цвет полосы (настройте по вашему выбору) */
  margin-top: 10px;
 
}
</style>